@font-face {
  font-family: 'Open Sans';
  src: url('./OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'Open Sans SemiBold';
  src: url('./OpenSans-SemiBold.ttf');
}

@font-face {
  font-family: 'Open Sans Bold';
  src: url('./OpenSans-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat SemiBold';
  src: url('./Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: 'Montserrat Bold';
  src: url('./Montserrat-Bold.ttf');
}

body {
  font-family: 'Open Sans' !important;
  color: #616b89 !important;
}

h1 {
  font-family: 'Montserrat' !important;
  font-size: 1.7143rem !important;
  font-weight: 800 !important;
  color: #616b89 !important;
}

h2 {
  font-family: 'Montserrat' !important;
  font-size: 1.2857rem !important;
  font-weight: 900 !important;
  color: #616b89 !important;
}

h3 {
  font-family: 'Montserrat' !important;
  font-size: 1.2857rem !important;
  font-weight: 700 !important;
  color: #616b89 !important;
}

a {
  cursor: pointer;
}

strong {
  font-family: 'Open Sans Bold' !important;
}

#root {
  height: 100%;
}

.w100 {
  width: 100%;
}

.ui.image.w100px {
  width: 100px;
  height: auto;
}

.h100 {
  height: 100%;
}

.mxw650 {
  max-width: 650px;
}

.bg-blue {
  background: linear-gradient(#2183eb, #5caaff);
}

div.no-bt > .ui.segment {
  border-top: none;
}

.ui.segments.br1 {
  border-radius: 1rem;
  box-shadow: 0 6px 16px 0 rgba(63, 68, 124, 0.33);
  /* border-radius: 24px; */
}
.ui.segments:not(.horizontal).br1 > .segment:first-child {
  border-radius: 1rem 1rem 0 0;
}
.ui.segments:not(.horizontal).br1 > .segment:last-child {
  border-radius: 0 0 1rem 1rem;
}

.ui.padb2 {
  padding-bottom: 2rem;
}

.ui.segments:not(.horizontal).pads4 > .segment {
  padding-left: 4rem;
  padding-right: 4rem;
}

.ui.header {
  color: #2181ec;
}

.ui.form input[type='password'] {
  height: 3.4rem;
  padding-bottom: 0.8rem;
}
.ui.form input[type='text'] {
  height: 3.4rem;
  padding-bottom: 0.5rem;
}

.header-item-group .header {
  padding-top: 0.6rem;
  padding-left: 0.4rem;
}

#hubspot-messages-iframe-container {
  z-index: 999 !important;
}
